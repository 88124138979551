.buttons {
  display: flex;
  justify-content: space-around;
}

.container {
  padding: 8px;
}

.hitline {
  margin: 10px 0;
}
