@import '@sassvars';

.dropdown {
  &:last-child {
    margin-right: 0;
  }
}

.dropdownv2 {
  // width: 18em !important;
  // & > div {
  //   border: 1px solid transparent;
  // }

  @include mobile {
    border: 1px solid $gray-light;
    border-radius: 4px;
  }
}

.gMapsContainer {
  display: flex;
  flex-flow: column nowrap;
  padding: 0.25rem 0;
  background: #fff;
  margin-top: 0.5em;
  border: 1px solid $gray-light;
  border-radius: 4px;
}

.linkGeneric {
  color: $blue-primary;
  position: relative;
}

.gMapsSuggestion {
  padding: 0.5rem 0.25rem;
  border-bottom: 1px solid $gray-light;
  cursor: pointer;

  &:hover {
    background: rgba($blue-primary, 0.1);
  }

  &:first-child {
    padding-top: 0.25rem;
  }

  &:last-child {
    border-bottom: 0;
    padding-bottom: 0.25rem;
  }

  &Active {
    background: rgba($blue-primary, 0.1);
  }
}
