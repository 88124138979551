@import '@sassvars';

.appWrapper {
  display: flex;
  flex-flow: row nowrap;

  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;

  width: 100vw;
  height: 100vh;

  margin: 0;
  padding: 0;

  @include tablet {
    flex-flow: column nowrap;
  }
}

@keyframes sidemenu-loading {
  0%,
  100% {
    background-color: var(--next--color--nav-menu);
  }
  50% {
    background-color: #003366;
  }
}

.sidemenuPlaceholder {
  animation: sidemenu-loading 1s linear infinite alternate;
  background-color: var(--next--color--nav-menu);
  height: 100%;
  min-width: 16rem;
  max-width: 20rem;
  color: var(--next--color--on-nav-menu);

  @include tablet {
    height: 3rem;
    padding: 0;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
  }
}
