@import '@sassvars';

.root {
  margin: 0;
  box-sizing: border-box;
}

.scroll {
  &X {
    overflow-x: auto;
    @include scrollbar();
  }

  &Y {
    overflow-y: auto;
    @include scrollbar();
  }

  &None {
    overflow: hidden;
  }
}

.p {
  &l {
    &None {
      padding-left: 0;
    }

    &Sm {
      padding-left: 0.5rem;
    }

    &Md {
      padding-left: 1rem;
    }

    &Lg {
      padding-left: 1.5rem;
    }
  }

  &r {
    &None {
      padding-right: 0;
    }

    &Sm {
      padding-right: 0.5rem;
    }

    &Md {
      padding-right: 1rem;
    }

    &Lg {
      padding-right: 1.5rem;
    }
  }

  &t {
    &None {
      padding-top: 0;
    }

    &Sm {
      padding-top: 0.5rem;
    }

    &Md {
      padding-top: 1rem;
    }

    &Lg {
      padding-top: 1.5rem;
    }
  }

  &b {
    &None {
      padding-bottom: 0;
    }

    &Sm {
      padding-bottom: 0.5rem;
    }

    &Md {
      padding-bottom: 1rem;
    }

    &Lg {
      padding-bottom: 1.5rem;
    }
  }
}

.m {
  &l {
    &None {
      margin-left: 0;
    }

    &Sm {
      margin-left: 0.5rem;
    }

    &Md {
      margin-left: 1rem;
    }

    &Lg {
      margin-left: 1.5rem;
    }
  }

  &r {
    &None {
      margin-right: 0;
    }

    &Sm {
      margin-right: 0.5rem;
    }

    &Md {
      margin-right: 1rem;
    }

    &Lg {
      margin-right: 1.5rem;
    }
  }

  &t {
    &None {
      margin-top: 0;
    }

    &Sm {
      margin-top: 0.5rem;
    }

    &Md {
      margin-top: 1rem;
    }

    &Lg {
      margin-top: 1.5rem;
    }
  }

  &b {
    &None {
      margin-bottom: 0;
    }

    &Sm {
      margin-bottom: 0.5rem;
    }

    &Md {
      margin-bottom: 1rem;
    }

    &Lg {
      margin-bottom: 1.5rem;
    }
  }
}

.grow {
  &Sm {
    flex-grow: 1;
  }

  &Md {
    flex-grow: 5;
  }

  &Lg {
    flex-grow: 10;
  }
}

.fullHeight {
  min-height: 100%;
  max-height: 100%;
}

.fullWidth {
  min-width: 100%;
  max-width: 100%;
}
