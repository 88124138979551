@import '@sassvars';

@mixin test-border($color) {
  // border: 4px solid $color;
}

.page {
  display: flex;
  flex-flow: column nowrap;
  flex-grow: 1;
  box-sizing: border-box;

  overflow: auto;

  @include test-border(red);

  @include scrollbar();
}

.header {
  display: flex;
  flex-flow: column nowrap;
  box-sizing: border-box;

  @include test-border(blue);

  &Title {
    display: flex;
    flex-flow: row;
    justify-content: flex-start;
    align-items: center;

    & > span:not(:last-child) {
      margin-right: 0.5rem;
    }
  }
}

.content {
  display: flex;
  flex-flow: column nowrap;
  flex-grow: 5;
  box-sizing: border-box;

  @include test-border(blue);

  &Scroll {
    overflow: auto;
    @include scrollbar();
  }

  &Centered {
    justify-content: center;
    align-items: center;
  }

  &WithChildWrapper {
    align-items: center;
  }

  &ChildWrapper {
    display: flex;
    flex-flow: column nowrap;
    flex-grow: 1;
    box-sizing: border-box;

    @include test-border(green);
  }
}

.demo {
  &Title {
    margin-right: 0.25em;
  }

  &TitleSuffix,
  &Availability {
    opacity: 0.67;
  }

  &Overview {
    min-width: 25rem;

    @include mobile {
      min-width: 15rem;
    }
  }
  &Preview {
    min-width: 25rem;
    max-width: 60rem;
  }
}

.maxWidth {
  &Tiny {
    max-width: map-get($breakpoint, 'tiny');
  }

  &Mobile {
    max-width: map-get($breakpoint, 'mobile');
  }

  &Tablet {
    max-width: map-get($breakpoint, 'tablet');
  }

  &LaptopSm {
    max-width: map-get($breakpoint, 'laptop-sm');
  }

  &DesktopSm {
    max-width: map-get($breakpoint, 'desktop-sm');
  }

  &DesktopMd {
    max-width: map-get($breakpoint, 'desktop-md');
  }
}

.disableOverflowX {
  overflow-x: hidden;
}

.disableOverflowY {
  overflow-y: hidden;
}

.px {
  &None {
    padding-left: 0;
    padding-right: 0;
  }

  &Sm {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }

  &Md {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  &Lg {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

.py {
  &None {
    padding-top: 0;
    padding-bottom: 0;
  }

  &Sm {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }

  &Md {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  &Lg {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
}

.bright {
  background: $white;
}

.loader {
  @include test-border(green);

  display: flex;
  justify-content: center;

  padding: 1rem;

  &CenterContent {
    flex-flow: column nowrap;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
  }
}

.error {
  color: $red;
}

.message {
  @include test-border(green);

  display: flex;
  justify-content: center;

  font-size: 1rem;
  line-height: 1.5;

  padding: 1rem;

  &CenterContent {
    flex-flow: column nowrap;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
  }

  &Header {
    font-size: 3rem;
    font-weight: lighter;
    line-height: 1.5;
  }

  &NoHeader {
    font-size: 2rem;
  }
}

.drawer {
  display: flex;
  flex-flow: row nowrap;
  flex-grow: 1;
  box-sizing: border-box;
  transition: all 0.15s ease;

  @include test-border(lime);

  &Handle {
    display: flex;
    flex-flow: column nowrap;
    box-sizing: border-box;
    background-color: transparent;
    cursor: ew-resize;
    border: none;
    outline: none;

    border-left: 0.0625rem solid #ced4da;

    &:hover {
      background-color: #ced4da77;
    }

    &:hover,
    &:focus {
      border-left: 0.125rem solid #007bc0;
    }

    @include test-border(purple);
  }

  &Resizing &Handle {
    background-color: #ced4da77;
  }

  &Content {
    --drawer-content--height: calc(100vh - var(--next--app-bar--height, 0));

    display: flex;
    flex-flow: column nowrap;
    flex-grow: 10;
    box-sizing: border-box;
    transition: all 0.15s ease;
    background-color: white;
    min-height: var(--drawer-content--height);
    max-height: var(--drawer-content--height);

    @include test-border(orange);

    &Scroll {
      overflow: auto;
      @include scrollbar();
    }

    &DisableScroll {
      overflow: hidden;
    }
  }

  &Wrapper {
    display: flex;
    flex-flow: row nowrap;
    flex-grow: 1;
    box-sizing: border-box;

    @include test-border(pink);
  }
}
