@import '@sassvars';
/* lato-regular - latin */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  src:
    local('Lato Regular'),
    local('Lato-Regular'),
    url('/fonts/lato-v16-latin/lato-v16-latin-regular.woff2') format('woff2'),
    /* Chrome 26+, Opera 23+, Firefox 39+ */ url('/fonts/lato-v16-latin/lato-v16-latin-regular.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* lato-700 - latin */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 500;
  src:
    local('Lato Bold'),
    local('Lato-Bold'),
    url('/fonts/lato-v16-latin/lato-v16-latin-700.woff2') format('woff2'),
    /* Chrome 26+, Opera 23+, Firefox 39+ */ url('/fonts/lato-v16-latin/lato-v16-latin-700.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
html {
  scroll-behavior: smooth;
}

body {
  padding: 0;
  margin: 0;
  font-family: 'Lato', sans-serif;
  font-size: 16px;
  color: $black;
  background-color: #fafafa;
}

.lock-scroll-body {
  overflow: hidden;
}

body > .dd2__multi-value {
  z-index: 2000;
}

.tiptap-print > .tiptap.ProseMirror {
  display: none;
}

@media print {
  .tiptap-print {
    & > * {
      display: none !important;
    }

    & > .tiptap.ProseMirror {
      display: block !important;

      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        page-break-after: avoid;
      }
      table,
      figure {
        page-break-inside: avoid;
      }

      // .data-summary {
      //   page-break-inside: avoid;
      // }
    }
  }

  @page {
    size: auto;
    margin: 10mm;
  }
}

* {
  box-sizing: border-box;
  font-family: 'Lato', sans-serif !important;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}

ul,
ol {
  margin: 0.25rem 0;
  padding-left: 1.75rem;
}

a {
  color: $blue-primary;
  text-decoration: none;
}

.link {
  color: $blue-primary;
  cursor: pointer;
}

.red-link {
  color: $red;
  cursor: pointer;
}

.input {
  font-size: 16px;
  border: 1px solid #cccccc;
  border-radius: 4px;
  padding: 2px 8px;
  height: 2.375rem;
  width: 18em;
}

.textarea {
  width: 90%;
  min-height: 4rem;
  font-size: 16px;
  padding: 0.25rem 0.5rem;
  border: 1px solid #cccccc;
  border-radius: 4px;
  width: 18em;
}

// Text
.bold {
  font-weight: bold;
}

.blue {
  color: $blue-primary;
}

.hover-blue {
  cursor: pointer;
  &:hover {
    color: $blue-primary;
  }
}

.white {
  color: $white;
}

.red {
  color: $red;
}

.gray {
  color: $gray;
}

.grayMed {
  color: $gray-medium;
}

.grayLight {
  color: $gray-light;
}

.green {
  color: $green;
}

.yellow {
  color: $yellow;
}

.header {
  font-size: $font-header;

  &--center {
    @extend .header;
    text-align: center;
  }
}

.subheader {
  font-size: $font-subheader;
  font-weight: 500;
}
.smallsubheader {
  font-size: $font-base;
  font-weight: 500;
}
.smallheader {
  font-size: $font-smallheader;
  font-weight: 500;

  @include mobile {
    font-size: 22px;
  }
}

.textExtraSmall {
  font-size: 0.75em;
}

.textSmall {
  font-size: 12px;
}
.textMed {
  font-size: 14px;
}

.textLarge {
  font-size: $font-text--large;
}

.textCenter {
  text-align: center;
}

// Layout
.grid-col {
  flex: 1;
  margin: 0 0.25em;

  &:first-child {
    margin-left: 0;
  }

  &:last-child {
    margin-right: 0;
  }
}

.flexBoxRow {
  display: flex;
  flex-flow: row;

  @include mobile {
    flex-flow: row wrap;
  }

  &--center {
    @extend .flexBoxRow;
    align-items: center;
  }
  &--wrap {
    @extend .flexBoxRow;
    flex-wrap: wrap;
  }
}

.flexEnd {
  justify-content: flex-end;
}

.flexBoxCol {
  display: flex;
  flex-flow: column;
}

.container {
  display: flex;
  flex-flow: column nowrap;
  padding: 1rem;
  flex-grow: 1;

  overflow: auto;

  &--fullwhite {
    @extend .container;
    background: white;
    margin-left: 12rem;
    margin-right: 0;
    padding: 1em;
    margin-top: 0;
    width: calc(100% - 12em);

    @include tablet {
      height: auto;
      margin-left: 0;
      margin-right: 0;
      width: 100%;
      padding: 0.5rem;
      padding-bottom: 1rem;
    }
  }

  &--fullwidth {
    @extend .container;
  }

  &--centered {
    @extend .container;
  }

  &--vcentered {
    @extend .container;
    max-width: 40em;
    margin: 0 auto;
    padding-top: 9em;
    justify-content: center;
  }
  &--fullheight {
    @extend .container;
  }
}

// Margin
.margin-bot-05 {
  margin-bottom: 0.5rem;
}
.margin-bot-025 {
  margin-bottom: 0.25rem;
}

.margin-bot-1 {
  margin-bottom: 1rem;
}

.margin-bot-15 {
  margin-bottom: 1.5rem;
}

.margin-top-1 {
  margin-top: 1rem;
}

.margin-top-3 {
  margin-top: 3rem;
}

.margin-top-2 {
  margin-top: 2rem;
}

.margin-top-05 {
  margin-top: 0.5rem;
}

.margin-top-15 {
  margin-top: 1.5rem;
}

.margin-top-025 {
  margin-top: 0.25rem;
}

.margin-left-05 {
  margin-left: 0.5rem;
}

.margin-right-05 {
  margin-right: 0.5rem;
}

.absolute-top-right {
  position: absolute;
  top: 0.75em;
  right: 1em;
  padding: 0.5em 1em;

  @include mobile {
    top: 2.5rem;
    right: 0;
  }
}

// Form
.form {
  display: flex;
  flex-flow: column nowrap;
  width: 100%;
  max-width: 30em;
  margin: 0 auto;

  & > input {
    width: 100%;
    padding: 0.5em 0.75em;
    margin: 0.25em 0;
    border: 1px solid $gray-light;
    border-radius: 0.25em;
    box-shadow: 0 1px 3px 1px rgba($blue-primary, 0.1);
    font-size: 16px;

    &:focus {
      outline: none;
      border: 1px solid rgba($blue-primary, 0.5);
    }
  }

  & > button {
    max-width: 8em;
  }
}

.compactForm {
  display: flex;
  flex-flow: column nowrap;
  width: 100%;
  max-width: 30em;
  min-width: 15em;
  margin: 0 auto;

  & > input {
    width: 65%;
    padding: 0.5em 0.75em;
    margin: 0.25em 0;
    border: 1px solid $gray-light;
    border-radius: 0.25em;
    box-shadow: 0 1px 3px 1px rgba($blue-primary, 0.1);
    font-size: 16px;

    &:focus {
      outline: none;
      border: 1px solid rgba($blue-primary, 0.5);
    }
  }

  & > button {
    max-width: 8em;
  }
}

.label {
  margin-top: 0.25rem;
  margin-bottom: 0.1rem;

  &--bot {
    @extend .label;
    margin-bottom: 0.25rem;
  }
}

// Toast
.toast {
  border-radius: 0.25rem !important;
  box-shadow: $box-shadow--heavy;

  &Body {
    color: $black;
  }
}

// Overrides
.react-datepicker-wrapper {
  width: 100%;
}

.react-datepicker-popper {
  z-index: 10000000;
}

.disable-select {
  user-select: none;
}

.react-datepicker__day-names,
.react-datepicker__week {
  user-select: none;
}

.react-calendar-timeline .rct-horizontal-lines .rct-hl-odd,
.react-calendar-timeline .rct-sidebar .rct-sidebar-row.rct-sidebar-row-odd {
  background: rgba(10, 10, 10, 0.05);
}

.react-datepicker__close-icon::after {
  background-color: transparent;
  color: hsl(0, 0%, 80%);
  font-size: 22px;
}

.ce-block__content,
.ce-toolbar__content {
  max-width: 95%;
}

.collaboration-cursor__caret {
  border-left: 1px solid #0d0d0d;
  border-right: 1px solid #0d0d0d;
  margin-left: -1px;
  margin-right: -1px;
  pointer-events: none;
  position: relative;
  word-break: normal;
}

/* Render the username above the caret */
.collaboration-cursor__label {
  border-radius: 3px 3px 3px 0;
  color: #0d0d0d;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  left: -1px;
  line-height: normal;
  padding: 0.1rem 0.3rem;
  position: absolute;
  top: -1.4em;
  user-select: none;
  white-space: nowrap;
}

.ProseMirror.resize-cursor {
  cursor: col-resize;
}

.ProseMirror {
  .resize-cursor {
    cursor: col-resize;
  }
  .tableWrapper {
    margin-top: 3rem; // Assuming Tailwind's my-12 corresponds to 3rem; adjust as needed
    margin-bottom: 3rem;
  }

  table {
    border-collapse: collapse;
    border: 1px solid rgba(0, 0, 0, 0.1); // Adjust the alpha value as needed
    border-radius: 0.375rem; // Example radius; adjust as needed
    box-sizing: border-box;
    width: 100%;
    border-color: rgba(0, 0, 0, 0.1);

    td,
    th {
      border: 1px solid rgba(0, 0, 0, 0.1); // Adjust as needed
      min-width: 100px;
      padding: 0.5rem; // Assuming p-2 corresponds to 0.5rem; adjust as needed
      position: relative;
      text-align: left;
      vertical-align: top;
      border-color: rgba(0, 0, 0, 0.1); // Adjust as needed

      &:first-of-type:not(a) {
        margin-top: 0;
      }

      p {
        margin: 0;

        & + p {
          margin-top: 0.75rem; // Assuming mt-3 corresponds to 0.75rem; adjust as needed
        }
      }
    }

    th {
      font-weight: bold;
    }

    .column-resize-handle {
      position: absolute;
      bottom: -2px;
      right: -0.25rem;
      top: 0;
      width: 0.5rem;
      display: flex;
      pointer-events: none;
      cursor: col-resize;

      &::before {
        content: '';
        background-color: rgba(0, 0, 0, 0.2); // Adjust as needed
        height: 100%;
        width: 1px;
        margin-left: 2px;
        background-color: rgba(0, 0, 0, 0.1);
      }
    }

    .selectedCell {
      background-color: rgba(0, 0, 0, 0.05); // Adjust as needed
      border: double 1px rgba(0, 0, 0, 0.2); // Adjust as needed
      // background-color: rgba(0, 0, 0, 0.1); // Adjust as needed
      // border-color: rgba(0, 0, 0, 0.1); // Adjust as needed
    }

    .grip-column,
    .grip-row {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      z-index: 10;
      background-color: rgba(0, 0, 0, 0.05);
      cursor: pointer;

      left: -0.75rem;
      top: 0;
      margin-top: -1px;
      height: calc(100% + 1px);
      width: 0.75rem;
      border-top-width: 1px;

      &.first {
        border: transparent;
        border-radius: 0.2rem 0 0; // Adjust for rounded-tl-sm; check Tailwind config
      }

      &.last {
        border-radius: 0 0.2rem 0; // Adjust for rounded-tr-sm; check Tailwind config
      }

      &.selected {
        background-color: rgba(0, 0, 0, 0.3); // Adjust as needed
        border-color: rgba(0, 0, 0, 0.3); // Adjust as needed
        box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.3); // Example shadow; adjust as needed

        &::before {
          content: '';
          border-style: dotted;
          border-width: 2px;
        }
      }

      &:hover {
        background-color: rgba(0, 0, 0, 0.1); // Adjust as needed\

        &::before {
          border-style: dotted;
          border-width: 2px;
          border-color: rgba(0, 0, 0, 0.6); // Adjust as needed
        }
      }
    }

    .grip-column {
      left: 0;
      top: -0.75rem;
      margin-left: -1px;
      height: 0.75rem;
      width: calc(100% + 1px);
      border-left-width: 1px;

      // @media (prefers-color-scheme: dark) {
      //   border-color: rgba(255, 255, 255, 0.2); // Adjust as needed
      // }
    }

    .grip-row {
      left: -0.75rem;
      top: 0;
      margin-top: -1px;
      height: calc(100% + 1px);
      width: 0.75rem;
      border-top-width: 1px;

      // @media (prefers-color-scheme: dark) {
      //   border-color: rgba(255, 255, 255, 0.2); // Adjust as needed
      // }
    }
  }
}

:root {
  --next--color--nav-menu: #002952;
  // Used for creating scroll shadows.
  --next--color--nav-menu--transparent: #00295200;
  --next--color--on-nav-menu: #ffffff;
  --next--color--scrollbar--track: #003366;
  --next--color--scrollbar--thumb: #577899;
  --next--color--scrollbar--thumb--hover: #007bc0;
  --next--color--nav-menu--divider: #ffffff33;
  --next--color--nav-menu-item--hover: #007bc0;

  // Height of the app bar (when in mobile view)
  --next--app-bar--height: 0;
}

@include tablet {
  :root {
    --next--app-bar--height: 64px;
  }
}

.with-scrollbar {
  @include scrollbar();
}
