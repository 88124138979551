@import '@sassvars';

.bounded-range-container {
  max-width: 32.5em;
  width: 32.5em;
  display: flex;
  flex-direction: row;
}

.mobile {
  @include tablet {
    flex-wrap: wrap;
  }

  @include mobile {
    flex-wrap: wrap;
  }
}

.bounded-range-container > div {
}

.mobile .range-label {
  @include tablet {
    flex-grow: 1;
  }

  @include mobile {
    flex-grow: 1;
  }
}

.bounded-range-container .range-label {
  width: 8em;
}

.bounded-range-container .date-range {
  width: 24.5em;
  display: flex;
}

.small-text :global(.cdf-range-label) :global(.dd2__placeholder) {
  font-size: 13px;
}
