@import '@sassvars';

.container {
  // https://developer.mozilla.org/en-US/docs/Web/CSS/visibility#interpolation
  transition:
    opacity 160ms,
    visibility 160ms;
  visibility: hidden;

  // Height is 150% because on mobile browsers there may be short clipping
  // even when scrolling is not allowed, they can scroll up to hide the
  // nav bar. When the scroll up to hide the nav bar, the background
  // size of this container doesn't update until the scrolling stops,
  // so they will see the main page below.
  height: 150%;
  width: 100%;
  opacity: 0;
  z-index: 1401;
  pointer-events: none;

  text-align: center;
  position: fixed;
  overflow: hidden;
}

.fullscreen-container {
  height: 100%;
}

.dim {
  background-color: rgba(0, 0, 0, 0.2);
}

.fly-in-modal {
  display: inline-block;
  transition:
    margin-top 160ms,
    opacity 160ms;
  margin: auto;
  background: #fff;
  border-radius: 4px;

  text-align: left;
  box-shadow: $box-shadow--hover;
  opacity: 0;
  margin-top: 42vh;
  transform: translate(0, -50%);
  @include tablet {
    // width: 96%;
    // height: 90vh;
  }

  max-width: 96%;
  overflow: hidden;
}

.visible {
  opacity: 1;
  z-index: 1401;
  visibility: visible;
  pointer-events: all;
}

.visible .fly-in-modal {
  margin-top: 46vh;
  opacity: 1;
}

.visible .fullscreen {
  margin-top: 0;
  max-width: 100vw;
  transform: none;
}

.lock-scroll {
  overflow: hidden;
}
