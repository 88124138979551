@import '@sassvars';
.button {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  user-select: none;
  padding: 0.33em 0.5em;
  margin: 0.25em;
  margin-left: 0;
  background: #fff;
  border: 1px solid $gray-light;
  border-radius: 0.25em;
  font-size: 16px;
  color: $black;
  white-space: nowrap;
  line-height: 17px;

  &:focus {
    outline: none;
  }

  &:hover {
    color: $blue-primary;
    box-shadow: $box-shadow--hover;
  }
}

.buttonPrimary {
  @extend .button;
  background: $blue-primary;
  color: $white;

  &:hover {
    color: $white;
  }
}

.buttonDisabled {
  @extend .button;
  background: $gray-light;
  color: $gray-medium;
  cursor: default;

  &:hover {
    color: $gray-medium;
    box-shadow: none;
  }
}

.buttonRed {
  @extend .button;
  background: $red;
  color: $white;

  &:hover {
    color: $white;
  }
}

.buttonRedSoft {
  @extend .button;
  background: $red-soft;
  color: $white;

  &:hover {
    color: $white;
  }
}

.buttonBlue {
  @extend .button;
  background: $blue-primary;
  color: $white;

  &:hover {
    color: $white;
  }
}

.buttonGreen {
  @extend .button;
  // background: $green;
  background: $green-soft;
  color: $white;

  &:hover {
    color: $white;
  }
}

.buttonLpCta {
  @extend .button;
  background: $blue-primary;
  // background: linear-gradient(
  //   0deg,
  //   $blue-primary 1px,
  //   darken($blue-primary, 5) 2px,
  //   lighten($blue-primary, 10) 100%
  // );
  // background-origin: border-box;
  cursor: pointer;
  user-select: none;
  padding: 0.85rem 1.75rem;
  font-size: 18px;
  color: $white;
  // box-shadow: 0 2px 4px rgba(46, 52, 88, 0.32), 0 8px 12px rgba(46, 52, 88, 0.24);
  border: 1px solid lighten($blue-primary, 10);
  transition: box-shadow 0.2s ease;

  &:hover {
    color: $white;

    box-shadow:
      0 3px 5px rgba(46, 52, 88, 0.36),
      0 8px 14px rgba(46, 52, 88, 0.28);
  }

  & > a {
    color: $white;
    &:hover {
      color: $white;
    }
  }

  &Alt {
    @extend .buttonLpCta;
    background: #fff;
    color: $blue-primary;

    &:hover {
      color: lighten($blue-primary, 10);
    }
  }
}
