@import '@sassvars';

.datepicker {
  height: 2.375rem;
  padding: 2px 8px;
  width: 100%;

  // margin-right: 0.5em;
  border-radius: 4px;
  border: 1px solid hsl(0, 0%, 80%);
  font-size: 16px;
  font-family: 'Lato', sans-serif;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  position: relative;
}

.carets {
  font-size: 16px;
  color: $gray;
  cursor: pointer;
}

.select {
  padding: 0.25rem 0.5rem;
  text-align: left;
  border: 1px solid $gray-light;
  border-radius: 0.25rem;
  color: $black;
}
