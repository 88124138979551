.container {
  display: flex;
  align-items: center;
  min-width: 240px;

  transition: all 40ms ease-in-out;
}

.direction-toggle {
  transition: all 40ms ease-in-out;
  opacity: 0;
}

.direction-toggle-visible {
  opacity: 1;
}
