@import '@sassvars';
.logo {
  font-size: 30px;
  color: $gray;
  letter-spacing: 0.25em;
  margin: 0 auto;
  cursor: pointer;
}
.primaryLogo {
  margin: 0 auto;
}
