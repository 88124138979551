@import '@sassvars';
.container {
  z-index: 1000;
}

.topMenu {
  position: fixed;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  padding: 0.75rem 8%;
  top: 0;
  left: 0;
  width: 100%;
  background: $white;

  &NotFixed {
    @extend .topMenu;
    position: relative;
  }

  @include tiny {
    padding: 0.75rem 3%;
  }
}

.logoNavGroup {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}

.nav {
  padding-left: 5em;
  @include mobile {
    padding-left: 2em;
  }
}

.demo {
  margin-left: auto;
  margin-right: 1rem;
  font-size: 18px;
}

.login {
  color: $black;
  font-size: 18px;

  &:hover {
    color: $blue-primary;
  }
}

.withShadow {
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.15);
}

.blog {
  @media (max-width: 420px) {
    display: none;
  }
}
