// Colors
$black: #0a0b1d;
$gray: rgba(0, 0, 0, 0.68);
$gray-light: rgba(34, 36, 38, 0.15);
$gray-medium: rgba(107, 119, 140, 1);
$white: #ffffff;
$blue-primary: #007bc0;
$blue-dark: #003366;
$green-secondary: #00d0d1;
$green: #1c872a;
$green-soft: #3dc08d;
$teal: #7cdedc;
$red: #ce3e12;
$red-soft: #ec6c5b;
$background-lightblue: rgba($blue-primary, 0.1) !important;
$purple: #5a5387;
$yellow: #e6b641;
$box-shadow--std: 0 0 2px 1px rgba(10, 10, 10, 0.15);
$box-shadow--hover: 0 1px 4px rgba($black, 0.2);
$box-shadow--heavy:
  0 2px 4px rgba(46, 52, 88, 0.32),
  0 8px 12px rgba(46, 52, 88, 0.24);
$overlay: rgba(0, 0, 0, 0.55);

// Fonts
$font-header: 36px;
$font-smallheader: 28px;
$font-subheader: 20px;
$font-text--large: 18px;
$font-base: 16px;
$font-small: 12px;

// Breakpoints
$tiny-breakpoint: 380px;
$mobile-breakpoint: 520px;
$tablet-breakpoint: 940px;
$small-desktop-breakpoint: 1400px;
$desktop-breakpoint: 1920px;

// These match the Breakpoint enum
$breakpoint: (
  'tiny': $tiny-breakpoint,
  'mobile': $mobile-breakpoint,
  'tablet': $tablet-breakpoint,
  'laptop-sm': 1280,
  'desktop-sm': $small-desktop-breakpoint,
  'desktop-md': $desktop-breakpoint,
);

$small-desktop--height: 720px;

@mixin tiny {
  @media (max-width: $tiny-breakpoint) {
    @content;
  }
}

// Media queries
@mixin mobile {
  @media (max-width: $mobile-breakpoint) {
    @content;
  }
}

@mixin tablet {
  @media (max-width: $tablet-breakpoint) {
    @content;
  }
}

@mixin small-desktop {
  @media (max-width: $small-desktop-breakpoint) {
    @content;
  }
}

@mixin small-desktop--height {
  @media (max-height: $small-desktop--height) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: $tablet-breakpoint) {
    @content;
  }
}

@mixin large-desktop {
  @media (min-width: $desktop-breakpoint) {
    @content;
  }
}

@mixin hidden-in-printer-view {
  @media print {
    display: none !important;
  }
}

// Intended for wrapper elements, especially to help with children that scroll
@mixin wrapper($overflow: hidden, $fullHeight: false, $fullWidth: false) {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  overflow: $overflow;

  @if $fullHeight {
    min-height: 100%;
    max-height: 100%;
  }

  @if $fullWidth {
    min-width: 100%;
    max-width: 100%;
  }
}

@mixin scrollbar($size: 0.5rem, $track: #f1f1f1, $thumb: #888, $thumb-hover: #555, $track-radius: 0) {
  &::-webkit-scrollbar {
    width: $size;
    height: $size;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: $track;
    border-radius: $track-radius;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: $thumb;

    border-radius: $size;
    cursor: pointer;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: $thumb-hover;
  }
}

@mixin scroll-shadow($background-color, $background-color--transparent, $sizeA: 15px, $sizeB: $sizeA * 3) {
  background:
    linear-gradient($background-color 33%, $background-color--transparent),
    linear-gradient($background-color--transparent, $background-color 66%) 0 100%,
    radial-gradient(farthest-side at 50% 0, rgba(0, 0, 0, 0.35), rgba(0, 0, 0, 0)),
    radial-gradient(farthest-side at 50% 100%, rgba(0, 0, 0, 0.35), rgba(0, 0, 0, 0)) 0 100%;
  background-color: $background-color;
  background-repeat: no-repeat;
  background-attachment: local, local, scroll, scroll;
  background-size:
    100% $sizeB,
    100% $sizeB,
    100% $sizeA,
    100% $sizeA;
}
