@import '@sassvars';

.filter {
  max-width: 16em;
  width: 16em;
  min-width: 16em;
  margin: 0.25em;
}

.mobile {
  @include tablet {
    flex-grow: 1;
    max-width: unset;
    min-width: 0px;
  }

  @include mobile {
    flex-grow: 1;
    max-width: unset;
    min-width: 0px;
  }
}

.filter :global(.dd2__control),
.filter :global(.dd1__control),
.filter :global(.gmaps-dd__control),
.filter :global(.user-dropdown__control),
.filter :global(.org-dd__control),
.filter :global(.input),
.filter :global(.text-input),
.filter :global(.rtf-container),
.filter :global(.datefield) {
  border: 1px solid #cccccc;
  min-height: 38px;
  outline: none;
  box-shadow: none;
  max-width: 1000em;
}

.angle-down {
  margin: 0 8px;
  height: 16px;
  width: 16px;
  opacity: 0.5;
  transition: opacity 160ms;
}

.filter:hover .angle-down {
  opacity: 1;
}

.filter :global(.dd2__placeholder) {
  opacity: 0.7;
}
