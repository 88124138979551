@import '@sassvars';

@keyframes pulseStreamingDot {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

.streamingDot {
  display: inline-block;
  height: 1rem;
  width: 1rem;
  background-color: $blue-primary;
  border-radius: 50%;
  animation: pulseStreamingDot linear 1s infinite;
}

.variant {
  &Guide {
    font-size: 0.75rem;
    line-height: 1.55;
    text-decoration: none;
    font-weight: lighter;
  }

  &FeatureDescription {
    line-height: 1.35;
    opacity: 0.67;

    li {
      margin-bottom: 0.5em;
    }
  }

  &ModalContent {
    line-height: 1.35;

    p {
      margin: 0.5em 0;
    }

    li {
      margin-bottom: 0.5em;
    }
  }

  &AIResponse,
  &Chat {
    blockquote {
      font-size: 0.875rem;
      box-sizing: border-box;

      border-left: 0.5rem solid $blue-primary;
      border-radius: 0.25rem;
      background: rgba(0, 0, 0, 0.1);

      margin-inline-start: 0.5rem;
      margin-inline-end: 1rem;
      padding: 0.5rem 1rem;

      p {
        margin: 0.75rem 0;
      }
    }

    h1 {
      font-size: 1.75rem;
      font-weight: 300;
      margin-top: 0.5rem;
      margin-bottom: 0.25rem;
    }

    h2 {
      font-size: 1.5rem;
      font-weight: 300;
      margin-top: 0.5rem;
      margin-bottom: 0.5rem;
    }

    p {
      margin: 0.25rem 0;
      line-height: 1.5;
    }

    code {
      font-size: 0.8em;
      background-color: rgba(0, 0, 0, 0.1);
      display: inline-block;
      padding: 0.25em 0.5em;
      border-radius: 0.25em;
      font-weight: bold;
      font-family: monospace;
    }

    strong {
      font-weight: 500;
    }
  }
}
